import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import { mainContext } from "../MainComponent";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../MainComponent";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";

function BuySidebar(props: any) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const location = useLocation();
  const [selectedItem, setSelectedItem] = useState(location.pathname);
  const { quest_cart_id } =
    useContext(mainContext);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { store } = useContext(CountryContext);
  let cartId: any = quest_cart_id || "";
  let recentItem: any;
  let lastOrderSku: any;
  let lastOrder: any;
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { window } = props;
  const drawerWidth = 300;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [loggedUserCart] = useMutation(
    mutations.addToCartMutation.LOGGUSER_CART_DATA,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const routes = [
    { path: "", text: "layout.buysidebar.my_account" },
    { path: "my-order-list", text: "layout.buysidebar.my_order" },
    { path: "my-wishlist", text: "layout.buysidebar.my_wishlist" },
    { path: "address-book", text: "layout.buysidebar.addr_book" },
    { path: "edit-account-information", text: "layout.buysidebar.acc_info" },
    { path: "my-product-review", text: "layout.buysidebar.prod_review" },
    { path: "logout", text: "layout.buysidebar.logout" },
  ];
  const handleItemClick = (path: React.SetStateAction<string>) => {
    setSelectedItem(path);
  };
  const customerEmail = "example@email.com";
  const pageSize = 1;
  const currentPage = 1;

  const {
    data: recentOrderRes,
  } = useQuery(queries.customerQueries.GET_RECENT_CUSTOMER_ORDER, {
    variables: {
      customerEmail,
      pageSize,
      currentPage,
    },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  const { data: custQueryDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_CART_QUERY,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const { data: detailsdata } = useQuery(
    queries.detailsQueries.GET_CONFDETAILS,
    {
      variables: { sku: lastOrderSku },
    }
  );

  const detailsData = detailsdata?.products?.items[0];
  if (detailsData?.variants && detailsData?.variants.length > 0) {
    // variantsDetails = detailsData?.variants[lvalue].product
    // colorVariation = detailsData?.variants[lvalue].attributes[0].label
  }
  // let childSkuName= variantsDetails && variantsDetails !== undefined ? variantsDetails?.sku : detailsData?.sku
  const [addProductsToCart] = useMutation(
    mutations.addToCartMutation.ADD_SIMPLE_CART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const addSimpleCart = () => {
    return new Promise((resolve) => {
      addProductsToCart({
        variables: {
          // cartId: 'AdGvg2DjN1YedY5ZgoyIQvBJ8xHs0Ptq',
          cartId: cartId,
          quantity: 1.0,
          sku: lastOrderSku,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      })
        .then((response) => {
          // getCartId(cartId);
          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          resolve(false);
        });
    });
  };

  const [addConfigurableProductsToCart] = useMutation(
    mutations.addToCartMutation.ADD_CONFIG_PRODUCTS_TO_CART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const handleConfigProdCart = async () => {
    return new Promise(async (resolve) => {
      try {
       await addConfigurableProductsToCart({
          variables: {
            input: {
              cart_id: cartId,
              cart_items: [
                {
                  parent_sku: lastOrderSku,
                  data: {
                    quantity: 1.0,
                    sku: lastOrderSku,
                  },
                },
              ],
            },
          },
          context: {
            headers: {
              Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
              Connection: "keep-alive",
            },
          },
        });
        resolve(true);
        // getCartId(cartId);
      } catch (error) {
        resolve(false);
        console.error(error);
      }
    });
  };
  // eslint-disable-next-line array-callback-return
  recentOrderRes?.customer?.orders.items.map((data: any) => {
    // data.items.map((item:any)=>{
    //   recentItem = item.product_name

    // })
    lastOrder = recentOrderRes?.customer?.orders?.items;
    lastOrder.sort((a: any, b: any) => a.order_date - b.order_date);
    recentItem = lastOrder[0]?.items[0]?.product_name;
    lastOrderSku = lastOrder[0]?.items[0]?.product_sku;
  });

  const handleAddToCart = async () => {
    navigate(`/${store}/details/${lastOrderSku}`);
    if (loggedInToken !== "") {
      if (custQueryDataRes?.customerCart?.id !== "") {
        cartId = custQueryDataRes?.customerCart?.id;
        localStorage.setItem("quest_cart_id", cartId);
      } else {
        loggedUserCart({})
          .then(async (response) => {
            if (response.data && response.data.createEmptyCart) {
              cartId = response.data.createEmptyCart;
              localStorage.setItem("quest_cart_id", cartId);
            }
            // setCartData(detailsData)
            // if (detailsData?.variants && detailsData?.variants.length > 0) {

            //     await handleConfigProdCart();
            // }else{
            //     await addSimpleCart();
            // }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      if (cartId) {
        if (detailsData?.variants && detailsData?.variants.length > 0) {
          await handleConfigProdCart();
        } else {
          await addSimpleCart();
        }
        // getCartRefetch()
      }
    }
  };
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      {routes ? (
        <List>
          {/* {routes.map((item: any, index: any) => {
            return (
              <div key={index}>
                <ListItem key={item?.text} disablePadding>
                  <ListItemButton
                    sx={{ textAlign: "left" }}
                    onClick={() => handleClick(index)}
                  >
                    <ListItemText primary={t(item?.text)} />
                  </ListItemButton>
                </ListItem>
              </div>
            );
          })} */}
          {routes.map((route, index) => (
                <ListItemButton key={index}>
                  <Link
                    to={route.path}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <ListItemText primary={t(route?.text)} />
                  </Link>
                </ListItemButton>
              ))}
        </List>
      ) : (
        ""
      )}
      <Box sx={{ textAlign: "initial", pl: 2 }}>
        <Typography gutterBottom variant="h6" component="h6">
          {t("layout.buysidebar.recently_order")}
        </Typography>

        <FormControlLabel
          control={
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          }
          label={recentItem}
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          disabled={!isChecked}
          disableElevation
          onClick={handleAddToCart}
          sx={{
            my: 2,
            textTransform: "capitalize",
            color: "#fff",
            fontSize: "18px",
            fontWeight: "500",
            padding: "6px 34px",
          }}
        >
          {t("layout.buysidebar.add_to_cart_btn")}
        </Button>
      </Box>
    </Box>
  );
  return (
    <>
      {/* <IconButton
        className="toggle-nav-btn"
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 1, display: { md: "none" }, color: "#000" }}
      >
        {!mobileOpen ? <MenuIcon /> : <CloseIcon />}
      </IconButton> */}
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { md: "none", xs: "block" } }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{ width: { sm: "100%" }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box
          sx={{
            display: { xs: "none", md: "block" },
          }}
        >
          <Box
            sx={{ width: "100%", maxWidth: "100%", bgcolor: "#F0F2F2", mb: 3 }}
          >
            <List component="nav" aria-label="main mailbox folders">
              {routes.map((route, index) => (
                // <ListItemButton key={index}>
                //   <Link
                //     to={route.path}
                //     style={{ textDecoration: "none", color: "inherit" }}
                //   >
                //     <ListItemText primary={t(route?.text)} />
                //   </Link>
                // </ListItemButton>
                <ListItemButton
                  // key={index}
                  key={route.path}
                  component={Link}
                  to={route.path}
                  selected={selectedItem === route.path}
                  onClick={() => handleItemClick(route.path)}
                  sx={{
                    backgroundColor: selectedItem === route.path ? "#e0e0e0" : "inherit",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  {/* <Link
                    to={route.path}
                    style={{ textDecoration: "none", color: "inherit" }}
                  > */}
                    <ListItemText primary={t(route?.text)} />
                  {/* </Link> */}
                </ListItemButton>
              ))}
            </List>
          </Box>

          <Box sx={{ textAlign: "initial" }}>
            <Typography gutterBottom variant="h6" component="h6">
              {t("layout.buysidebar.recently_order")}
            </Typography>

            <FormControlLabel
              control={
                <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
              }
              label={recentItem}
              sx={{ mb: 2 }}
            />

            <Button
              variant="contained"
              disabled={!isChecked}
              disableElevation
              onClick={handleAddToCart}
              sx={{
                my: 2,
                textTransform: "capitalize",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "500",
                padding: "6px 34px",
              }}
            >
              {t("layout.buysidebar.add_to_cart_btn")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default BuySidebar;
