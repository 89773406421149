import Grid from "@mui/material/Grid";
import MyAccountPage from "./MyAccountPage";
import BuySidebar from "../Layout/BuySidebar";
import Newsletter from "./Newsletter";
import Container from "@mui/material/Container";
import EditAddressBook from "./EditAddressBook";
import MyWishList from "./MyWishList";
import AddressBook from "./AddressBook";
import EditAccountInfo from "./EditAccontInfo";
import MyProductReview from "./MyProductReview";
import { Route, Routes } from "react-router-dom";
import MyOrderList from "./MyOrderList";
import Order from "./Orders";
import AccountInfo from "./AccountInfo";
import "./buyer-dashboard.scss";
import TrackingOrder from "./TrackingOrder";

function BuyDashboard() {
  return (
    <Container maxWidth={false}>
      <div className="dashboard-wrapper">
        <Grid container sx={{ mt: 5, p: 3 }}>
          <Grid item md={3} xs={12}>
            <div className="sidebar-wrapper">
              <BuySidebar />
            </div>
          </Grid>
          <Grid item md={9} xs={12} sx={{ pl: { sm: 2, xs: 0 } }}>
            <div className="dash-content-wrap">
              {/* <MyAccountPage /> */}
              <Routes>
                <Route path="/" element={<MyAccountPage />} />
                <Route
                  path="/my-order-list/order/:orderNumber"
                  element={<Order />}
                />
                 <Route
                  path="/my-order-list/tracking/:orderNumber"
                  element={<TrackingOrder />}
                />
                <Route path="/my-wishlist" element={<MyWishList />} />
                <Route path="/my-order-list" element={<MyOrderList />} />
                <Route path="/address-book" element={<AddressBook />} />
                <Route
                  path="/address-book/edit-address-book/:addressType"
                  element={<EditAddressBook />}
                />
                <Route path="/newsletter" element={<Newsletter />} />
                <Route path="/account-information" element={<AccountInfo />} />
                <Route
                  path="/edit-account-information"
                  element={<EditAccountInfo />}
                />
                <Route
                  path="/my-product-review"
                  element={<MyProductReview />}
                />
                <Route
                  path="/my-account/edit-address-book/:addressType"
                  element={<EditAddressBook />}
                />
              </Routes>
              {/* <MyOrder/> */}
              {/* <Newsletter/>
                             
                            {/* <EditAddressBook/> */}
              {/* <MyWishList/> */}
              {/* <AddressBook /> */}
              {/* <EditAddressBook/> */}
              {/* <EditAccountInfo/> */}
              {/* <MyProductReview/> */}
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
export default BuyDashboard;
