import React, { useEffect, useState, useContext } from "react";
import { styled, Theme } from "@mui/system";
import {
  Unstable_Popup as Popup,
  PopupChildrenProps,
} from "@mui/base/Unstable_Popup";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Button, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import SvgIcon from "@mui/material/SvgIcon";
import Select from "@mui/material/Select";
import { useMutation } from "@apollo/client";
import * as mutations from "../../../operations/mutations";
import { Link } from "react-router-dom";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import { mainContext } from "../MainComponent";
import Toaster from "../../../components/toster";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../MainComponent";
import QuotationModal from "./quotationModal";
import { useMediaQuery } from '@mui/material';
import theme from "../../../theme";
import Drawer from '@mui/material/Drawer';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const qtyOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
];

function Addcart(props: any) {
  const { t } = useTranslation();
  const { quest_cart_id, setCartCount } = useContext(mainContext);
  const { store } = useContext(CountryContext);
  const [cartDetailsData, setCartDetailsData] = useState<any>([]);
  const [isEditClick, setIsEditClick] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState("");
  const loggedInToken = localStorage.getItem("loggedInCust");
  const [openQuotation, setOpenQuotation] = useState(false);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleOpenQuotation = () => {
    setOpenQuotation(true);
  };

  const handleCloseQuotation = () => {
    setOpenQuotation(false);
  };
  const {
    loading: cartDetailsLoading,
    data: cartDetailsRes,
    refetch: cartDetailsRefetch,
  } = useQuery(queries.cartQueries.GET_CART_DETAILS, {
    variables: { cart_id: quest_cart_id },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });
  let cartDetails = cartDetailsRes?.cart;
  useEffect(() => {
    setCartDetailsData(cartDetails);
  }, [cartDetails]);

  const [cartqty, setCartqty] = useState<any>();
  const [editQty, setEditQty] = useState(false);

  useEffect(() => {
    cartDetailsRefetch();
  }, [quest_cart_id, cartDetailsRefetch, store]);

  useEffect(() => {
    const qty: any = [];
    // eslint-disable-next-line array-callback-return
    cartDetails?.items.map((data: any) => {
      const qtydata = {
        id: data?.uid,
        qty: data?.quantity,
      };
      qty.push(qtydata);
    });
    setCartqty(qty);
  }, [cartDetails]);
  // cartDetails?.items?.length

  const [removeItemFromCart] = useMutation(
    mutations.addToCartMutation.REMOVECART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          Store: store
        },
      },
    }
  );

  const handleRemoveItemClick = (id: any) => {
    removeItemFromCart({
      variables: {
        // cartId: props?.isCartId,
        cartId: quest_cart_id,
        cartItemId: id,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          Store: store
        },
      },
    })
      .then((result) => {
        <Toaster msg="success" />;
        setCartDetailsData(result?.data?.removeItemFromCart?.cart);
        setCartCount(result?.data?.removeItemFromCart?.cart?.items?.length);
      })
      .catch((error) => {
        console.log("Error removing item from cart:", error);
      });
  };
  const handledChange = (event: any, id: any) => {
    // let cartdata : any = {}
    // cartdata = cartDetails.items
    // let uId= ""
    // cartDetails = cartDetails && cartDetails?.items.map((data: any) => {
    //   if (data.id === id) {
    //     uId = data.uid
    //     return {...data, quantity: event.target.value};
    //   }
    //   return data;

    // })
    const qty = cartqty.map((item: any) => {
      if (item.id === id) {
        item.qty = event.target.value;
      }
      return item;
    });
    setCartqty(qty);
    if (event.target.value !== "") {
      handleUpdateCart(event.target.value, id);
    }
  };
  const [updateCartItems] = useMutation(
    mutations.addToCartMutation.UPDATE_CART_ITEMS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          Store: store
        },
      },
    }
  );

  useEffect(() => {
    if (editQty) {
      cartDetailsRefetch();
      setIsEditEnabled("");
      setIsEditClick(false);
    }
    setEditQty(false);
  }, [editQty, cartDetailsRefetch]);

  const handleUpdateCart = async (qty: any, id: any) => {
    try {
      await updateCartItems({
        // variables: {
        //   input: {
           
        //     cart_id: quest_cart_id,
        //     cart_items: [
        //       {
        //         cart_item_uid: id,
        //         quantity: qty,
        //       },
        //     ],
        //   },
        // },
        variables: {
          cartId: quest_cart_id,
          cartItemUid: id,
          quantity: qty
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            Store: store
          },
        },
      }).then(() => {
        <Toaster msg="success" />;
      });
      setEditQty(true);
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  const StyledIconButton = styled(IconButton)`
    border: 1px solid #ece9e9; /* Change the color and width of the outline */
    border-radius: 2px;
    padding: 8px; /* Adjust padding as needed */
  `;

  const handleEditFun = (id: any) => {
    setIsEditEnabled(id);
    setIsEditClick(true);
  };
  const handleClose = () => {
    // handleUpdateCart();
    props.handleClosePopup();
    setIsEditClick(false);
    setIsEditEnabled("");
    // if (loggedInToken !== '' || gustToken !== '') {
    //   availableCart = gustToken;
    //   setChildData(availableCart);
    // }else{
    // }
  };
  function CustomSelectIcon(props: any) {
    return (
      <SvgIcon {...props}>
        <path
          d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
          fill="#000"
          fillOpacity="0.51"
        />
      </SvgIcon>
    );
  }

  // useEffect(() => {
  //   if (props.isCartId !== "") {
  //     refetch();
  //   }
  // }, [refetch]);

  const card = (
    <React.Fragment>
      {!isMobileScreen ? (
        <>
          <CardHeader
            action={
              <IconButton aria-label="settings" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          />

          <CardContent sx={{ paddingTop: 0 }}>
            <div className="cart-top-wrapper">
              <Typography
                sx={{ fontSize: 18, color: "#252525", fontWeight: "400" }}
                color="text.secondary"
                gutterBottom
              >
                {cartDetailsData?.items?.length} {t("layout.addcart.item_in_cart")}
              </Typography>
              <div className="sub-total-box">
                <Typography
                  sx={{ fontSize: 18, color: "#252525", fontWeight: "400" }}
                  color="text.secondary"
                  gutterBottom
                >
                  {t("layout.addcart.cart_subtotal")}
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ fontSize: 20, color: "#252525", fontWeight: "700" }}
                >
                  {cartDetailsData?.prices?.grand_total?.currency}&nbsp;
                  {cartDetailsData?.prices?.grand_total?.value}
                </Typography>
              </div>
            </div>
            <Divider />
            {cartDetailsData &&
              cartDetailsData?.items?.length > 0 &&
              cartDetailsData?.items.map((data: any, index: any) => (
                <div className="cart-content">
                  <Box sx={{ height: 100, width: 100 }}>
                    <CardMedia
                      component="img"
                      sx={{ width: "100%", height: "100%", objectFit: "contain" }}
                      image={data?.product?.small_image?.url}
                      alt={data?.product?.small_image?.__typename}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", width: 330 }}>
                    <CardContent sx={{ flex: "1 0 auto", pt: 0 }}>
                      <Typography
                        gutterBottom
                        component="div"
                        variant="h5"
                        sx={{ fontSize: 18, color: "#252525", fontWeight: "400" }}
                      >
                        {data?.product?.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        sx={{ fontSize: 20, color: "#252525", fontWeight: "700" }}
                      >
                        {data?.prices?.price?.currency}&nbsp;
                        {data?.prices?.price?.value}
                      </Typography>
                    </CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        pl: 1,
                        pb: 1,
                      }}
                    >
                      <div className="qty-box">
                        <label>{t("layout.addcart.qty")}</label>
                        <Box sx={{ minWidth: 100 }}>
                          {data?.quantity <= qtyOptions.length && !isEditClick ? (
                            // <h3>Default View</h3>
                            <FormControl
                              fullWidth
                              sx={{
                                border: "1px solid #C6C3C3",
                                borderRadius: "2px",
                              }}
                            >
                              <Select
                                IconComponent={CustomSelectIcon}
                                labelId="simple-select-label"
                                id="simple-select"
                                className="qty-select"
                                value={cartqty[index].qty}
                                disabled={data?.id !== isEditEnabled}
                                label="Quantity"
                                onChange={(e) => handledChange(e, data?.uid)}
                              >
                                {qtyOptions.map((option) => (
                                  <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          ) : (
                            <FormControl
                              // fullWidth
                              sx={{
                                border: "1px solid #C6C3C3",
                                borderRadius: "2px",
                                width: 100
                              }}
                            >
                              <TextField
                                type="number"
                                autoComplete="off"
                                name="Quantity"
                                required
                                fullWidth
                                id="Quantity"
                                placeholder="First Name"
                                disabled={data?.id !== isEditEnabled}
                                value={cartqty[index].qty}
                                onChange={(e) => handledChange(e, data?.uid)}
                                className="qty-textfield"
                              />
                            </FormControl>
                          )}
                        </Box>
                      </div>

                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                      >
                        <StyledIconButton
                          aria-label="edit"
                          onClick={() => handleEditFun(data?.id)}
                        >
                          <ModeEditIcon />
                        </StyledIconButton>

                        <StyledIconButton
                          aria-label="delete"
                          onClick={() => handleRemoveItemClick(data?.id)}
                        >
                          <DeleteIcon />
                        </StyledIconButton>
                      </Box>
                    </Box>
                  </Box>
                </div>
              ))}
          </CardContent>
          <CardActions sx={{ display: "grid" }}>
            <Link to={`/${store}/checkout`} style={{ width: "100%" }}>
              <Button
                onClick={handleClose}
                disableElevation
                variant="contained"
                color="primary"
                sx={{
                  mb: 2,
                  fontWeight: "600",
                  textTransform: "inherit",
                  padding: isMobileScreen ? "5px 8px" : "8px 16px",
                  fontSize: "16px",
                  color: "#FBF7F7",
                  width: "100%",
                }}
              >
                {t("layout.addcart.proceed_checkout")}
              </Button>
            </Link>
            <Link to="#" style={{ width: "100%", margin: 0 }}>
              <Button
                onClick={handleOpenQuotation}
                disableElevation
                variant="contained"
                color="primary"
                sx={{
                  mb: 2,
                  fontWeight: "600",
                  textTransform: "inherit",
                  padding: isMobileScreen ? "5px 8px" : "8px 16px",
                  fontSize: "16px",
                  color: "#FBF7F7",
                  width: "100%",
                }}
              >
                {t("layout.addcart.quotation")}
              </Button>
            </Link>
          </CardActions>
        </>
      ) : (
        <>

          <CardContent sx={{ py:0, px:1 }}>

            {cartDetailsData &&
              cartDetailsData?.items?.length > 0 &&
              cartDetailsData?.items.map((data: any, index: any) => (
                <div className="cart-content mobile-cart-content">
                  <Box sx={{ height: 100, width: 100 }}>
                    <CardMedia
                      component="img"
                      sx={{ width: "100%", height: "100%", objectFit: "contain" }}
                      image={data?.product?.small_image?.url}
                      alt={data?.product?.small_image?.__typename}
                    />
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column", width: 330 }}>
                    <CardContent sx={{ flex: "1 0 auto", pt: 0, pb: isMobileScreen ? '0 !important' : 2 }}>
                      <Typography
                        gutterBottom={!isMobileScreen}
                        component="div"
                        variant="h5"
                        sx={{ fontSize: 18, color: "#252525", fontWeight: "400" }}
                      >
                        {data?.product?.name}
                      </Typography>

                    </CardContent>
                  </Box>

                  <Box>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ fontSize: 20, color: "#252525", fontWeight: "700" }}
                    >
                      {data?.prices?.price?.currency}&nbsp;
                      {data?.prices?.price?.value}
                    </Typography>
                  </Box>

                </div>
              ))}
          </CardContent>
          <CardActions sx={{ display: "grid" }}>
          <Link to="#" style={{ width: "100%", margin: 0 }} onClick={handleOpenQuotation}>
              <Button
                disableElevation
                variant="text"
                color="primary"
                startIcon={<FileDownloadOutlinedIcon />}
               sx={{
                
                 fontWeight: "500",
                 textTransform: "Capitalize",

               }}
              >
                {t("layout.addcart.quotation")}
              </Button>
            </Link>
            <Box sx={{display:'flex', gap:'10px', mb:2}}>
            <Link to={`/${store}/checkout`} style={{ width: "100%" }}>
              <Button
                onClick={handleClose}
                disableElevation
                variant="contained"
                color="primary"
                sx={{
                  fontWeight: "500",
                  textTransform: "inherit",
                  padding: isMobileScreen ? "5px 8px" : "8px 16px",
                  fontSize: "14px",
                  color: "#FBF7F7",
                  width: "100%",
                }}
              >
                {t("layout.addcart.proceed_checkout")}
              </Button>
            </Link>
            <Link to="#" style={{ width: "100%" }}>
            <Button
            variant="outlined"
            color="primary"
            sx={{
              fontWeight: "500",
              textTransform: "inherit",
              padding: isMobileScreen ? "5px 8px" : "8px 16px",
              fontSize: "14px",
              width: "100%",
            }}
            onClick={props.isToggleDrawer}
            >
              {t("layout.addcart.continue-shopping")}
            </Button>
            </Link>
            </Box>
          </CardActions>
        </>
      )}
    </React.Fragment>
  );

  return (
    <>
      {/* isMobileScreen */}
      {/* { !isMobileScreen ?  */}

      {!cartDetailsLoading &&
        cartDetailsData &&
        cartDetailsData?.items?.length > 0 ? (
        <div>
          {/* {props.isopenpopup && <div className="overlay" />} */}
          {!isMobileScreen ?
            <>
              <Popup
                placement="bottom-end"
                className="add-cart-popup"
                anchor={props?.isAnchor}
                open={props?.isOpenPopup}
                withTransition
              >
                {(props: PopupChildrenProps) => (
                  <PopAnimation {...props}>
                    <PopupBody>
                      <Box sx={{ width: 460 }} style={{
                              direction: store === "ae_ar" || store === "sa_ar" || store === "ar" ? 'rtl' : 'ltr',
                              textAlign: store === "ae_ar" || store === "sa_ar" || store === "ar" ? 'right' : 'left'
                            }}>
                        <Card variant="outlined" sx={{ border: 0 }}>
                          {card}
                        </Card>
                      </Box>
                    </PopupBody>
                  </PopAnimation>
                )}
              </Popup>
            </>
            : (
              <Drawer anchor="top" open={props.isOpenMobileDrawer || props?.isOpenPopup} onClose={props.isToggleDrawer || props.handleClosePopup}>
                <div
                  role="presentation"
                  onClick={props.isToggleDrawer}
                  onKeyDown={props.isToggleDrawer}
                >
                  <Box sx={{ width: '100%' }}>
                    <Card variant="outlined" sx={{ border: 0 }}>
                      {card}
                      {/* <h3>Open Drawer</h3> */}
                    </Card>
                  </Box>
                </div>
              </Drawer>
            )}
        </div>
      ) : (
        ""
      )}
      <QuotationModal open={openQuotation} close={handleCloseQuotation} setOpenQuotation={setOpenQuotation} />
    </>
  );
}
export default Addcart;

function Animated(
  props: React.PropsWithChildren<{
    className?: string;
    requestOpen: boolean;
    onEnter: () => void;
    onExited: () => void;
  }>
) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  React.useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = React.useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div
      onAnimationEnd={handleAnimationEnd}
      className={className + (requestOpen ? " open" : " close")}
    >
      {children}
    </div>
  );
}

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;

const grey = {
  50: "#f6f8fa",
  200: "#d0d7de",
  500: "#6e7781",
  700: "#424a53",
  900: "#24292f",
};

const PopupBody = styled("div")(
  ({ theme }: { theme: Theme }) => `
    width: max-content;
    padding: 0.5rem 1rem;
    margin: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[900] : grey[50]};
    border-radius: 8px;
    box-shadow: ${theme.palette.mode === "dark"
      ? `0px 4px 8px rgb(0 0 0 / 0.7)`
      : `0px 4px 8px rgb(0 0 0 / 0.1)`
    };
    min-height: 3rem;
    display: flex;
    align-items: center;
`
);
