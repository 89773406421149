import { gql } from "@apollo/client";

export const GET_PRODUCT_DETAILS = gql`
  query getProductSellerDetails($url_key: String!) {
    products(filter: { url_key: { eq: $url_key } }) {
        items {
          name
          delivery_estimation
          top_badge_left
          top_badge_left_title
          top_badge_right
          top_badge_right_title
          sellerData {
            qty
            price
            currency
            seller_id
            seller_name
            delivery_note
            warranty
          }
          sku
          url_key
          meta_title
          meta_keyword
          meta_description
          stock_status
          short_description {
              html
          }
          attributes {
              label
              value
          }
          review_count
          breadcrumb
        rating_summary
        reviews {
            items {
                average_rating
                summary
                text
                created_at
                nickname
            }
        }
        small_image {
            disabled
            label
            position
            url
            }
          description {
              html
          }
          ... on ConfigurableProduct {
                media_gallery {
                    url
                    label
                    position
                }
                variants {
                    attributes {
                        uid
                        label
                        code
                    }
                    product {
                        small_image 
                        {
                            label
                            url
                        }
                    }
                }
                configurable_options{
                    values {
                        label
                        swatch_data{
                            value
                        }
                    }
                }
    
          }
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
                discount {
                    amount_off
                    percent_off
                }
                final_price {
                    value
                    currency
                }
            }
          }
          image {
            url
            small_url
            label
          }
          small_image{
              url
              label
          }
          media_gallery {
              url
              thumbnail_url
              label
              ... on ProductVideo {
                  video_content {
                      media_type
                      video_provider
                      video_url
                      video_title
                      video_description
                      video_metadata
                  }
              }
          }
          related_products {
            uid
            name
            small_image{
                url
                label
            }
            price_range {
                minimum_price {
                    regular_price {
                        value
                        currency
                    }
                }
            }
          }
          upsell_products {
            uid
            name
          }
          crosssell_products {
            uid
            name
          }
        }
      }
    }
`;


