import React from "react";
import LoginHeader from "../Layout/login-header";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ThanksCart from '../../../NewAssets/image/cart-img.png';
import CardMedia from '@mui/material/CardMedia';


const card = (
    <React.Fragment>
        <CardContent sx={{ width: 550, margin: 'auto' }}>
            <Box sx={{ height: 64, width: 64, margin: 'auto' }}>
                <CardMedia
                    component="img"
                    image={ThanksCart}
                    alt="Paella dish"
                />
            </Box>


            <Typography variant="h4" component="div" sx={{ fontSize: 30, fontWeight: 700 }} color="#fff" gutterBottom>
                Thanks for Registering with Jebelz!
            </Typography>
            <Typography variant="h5" component="div" sx={{ fontSize: 18, fontWeight: 400, lineHeight: '30px' }} color="#fff">
                We are reviewing your seller registration request, please wait while your request is being reviewed
            </Typography>
        </CardContent>
        <CardActions className="thank-reg-box" sx={{ p: 5 }}>
            <div className="go-site-wrap">
                <Typography variant="h5" component="div" sx={{ fontSize: 18, fontWeight: 400 }} color="#000">
                    Go to the Website
                </Typography>

                <Button variant="contained" disableElevation sx={{ textTransform: 'unset', color: '#fff', backgroundColor: '#10CDF2', padding: '10px 70px' }}>www.jebelz.com</Button>
            </div>
        </CardActions>
    </React.Fragment>
);
function RegSuccess() {

    return (
        <div className="login-wrapper">
            <LoginHeader />
            <div className="reg-success-wrap">
                <Grid container spacing={2} sx={{ mt: 0 }}>
                    <Grid item xs={12}>
                        <Box sx={{ width: 980, m: 'auto' }}>
                        {/* <button onClick={handleGenerateToken}>Generate Token</button> */}
                            <Card className='reg-thank-card' sx={{overflow:'inherit', boxShadow:'none'}}>{card}</Card>
                        </Box>
                    </Grid>

                </Grid>
            </div>
        </div>
    );
}
export default RegSuccess;