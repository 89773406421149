import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Box, Grid, Typography, styled } from "@material-ui/core"
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Zoom from '@mui/material/Zoom';
import { CountryContext } from "../MainComponent";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: '#000',
    maxWidth: '100%',
    fontSize: theme.typography.pxToRem(12),
    borderRadius: 0,
    marginTop: '0 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px'
  },
}));

const SubMenu = (props: any) => {
  const { store } = useContext(CountryContext)
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeSubCategory, setActiveSubCategory] = useState(null);

  const handleCategoryClick = (category: any, option: any) => {
    setActiveCategory(category);
    setActiveSubCategory(option);
  };
  return (
    <Box sx={{
      width: '100%',
      bgcolor: '#232f3e',
      display: { xs: 'none', md: 'block' }
    }}>
      <div className="sub-header-wrap">
        <Grid container>
          <Grid item xs={12}>
            {props.menuData ?
              <ul className='category-wrap list-unstyled'>
                {props.menuData && props.menuData.map((item: any, index: any) => (
                  <li key={index} className={item === activeCategory ? 'activeCategory' : ''}>
                    <HtmlTooltip
                      TransitionComponent={Zoom}
                      title={item.children?.length > 0 ?
                        item.children.map((option: any) => (
                          <React.Fragment>
                            <div className="inner-menu-list">
                              <Typography color="inherit">
                                <div>
                                  <Link
                                    onClick={() => handleCategoryClick(item, option)}
                                    className={option === activeSubCategory ? 'activeSubCategory' : 'inner-menu-anchor'}
                                    to={`/${store}/category/${option?.url_path}.html`}
                                  >
                                    {option?.name}
                                  </Link>
                                </div>
                              </Typography>
                            </div>
                          </React.Fragment>
                        ))
                        : ''}>
                      <Link to={`/${store}/category/${item?.url_path}.html`} className="sub-head-btn" onClick={() => handleCategoryClick(item, null)}>{item?.name}<span><KeyboardArrowDownOutlinedIcon /></span></Link>
                    </HtmlTooltip>
                  </li>
                ))}

              </ul>
              : ''}
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}

export default SubMenu