import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormControl, InputLabel } from "@mui/material";
import TextField from "@mui/material/TextField";
import * as mutations from "../../../operations/mutations";
import { useMutation } from "@apollo/client";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import { useParams } from "react-router";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../MainComponent";

function CustomSelectIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
        fill="#000"
        fillOpacity="0.51"
      />
    </SvgIcon>
  );
}

interface FormDataState {
  id: number | null;
  region: {
      region: string;
      region_code: string;
  };
  company: string;
  country_code: string;
  street: string[];
  telephone: string;
  postcode: string;
  city: string;
  firstname: string;
  lastname: string;
  default_shipping: boolean;
  default_billing: boolean;
}

function EditAddressBook() {
  const { store } = useContext(CountryContext);
  const { t } = useTranslation();
  const [defaultBilling, setDefaultBilling] = useState(false);
  const [defaultShipping, setDefaultShipping] = useState(false);
  const [formData, setFormData] = useState<FormDataState>({
    id: null,
    region: {
      region: "Dubai",
      region_code: "Dubai",
    },
    company: "",
    country_code: "AE",
    street: [""],
    telephone: "",
    postcode: "",
    city: "",
    firstname: "",
    lastname: "",
    default_shipping: false,
    default_billing: false,
  });
  const [formErr, setFormErr] = useState({
    region: {
      region: "",
      region_code: "",
    },
    company: "",
    country_code: "",
    street: "",
    telephone: "",
    postcode: "",
    city: "",
    firstname: "",
    lastname: "",
    default_shipping: false,
    default_billing: false,
  });
  const [custCountry, setCustCountry] = useState(String());
  const [state, setState] = useState([]);
  const [custState, setCustState] = useState("");

  const {
    data: countryRegRes,
  } = useQuery(queries.configurationQueries.GET_COUNTRIES, {
    context: {
      headers: {
        Connection: "keep-alive",
      },
    },
  });
  const countries = countryRegRes?.countries;

  useEffect(() => {
    if (countries && countries.length > 0) {
      // eslint-disable-next-line array-callback-return
      const data = countries.filter((item: any) => {
        if (item?.two_letter_abbreviation === custCountry) {
          return item?.available_regions;
        }
      });
      setState(data[0]?.available_regions);
    }
  }, [custCountry, countries]);

  let onSubmitFunction;
  let newAddressData: any; 
  const params = useParams();
  const loggedInToken = localStorage.getItem("loggedInCust");

  /* const [createAddress] = useMutation(
    mutations.customerMutation.CREATE_ADDRESS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  ); */

  /* const handleCreateAddress = (event: any) => {
    validateForm();
    event.preventDefault();
    createAddress({
      variables: {
        input: formData,
      },
    })
      .then((response) => {
        const newAddress = response.data.createCustomerAddress;
        newAddressData = newAddress;
      })
      .catch((error) => {});
  };
 */
  const { data: custDetailsDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const custDetails = custDetailsDataRes?.customer;
  console.log("custDetails", custDetails)

  useEffect(() => {
    if (custDetails) {
      const add = custDetails?.addresses.filter(
        (item: any) => item.default_shipping === true
      );
      if (add && add.length > 0) {
        const data = {
          id: parseInt(add[0]?.id),
          region: {
            region: add[0]?.region?.region,
            region_code: add[0]?.region?.region_code,
          },
          company: "",
          country_code: add[0]?.country_code,
          street: add[0]?.street,
          telephone: add[0]?.telephone,
          postcode: add[0]?.postcode,
          city: add[0]?.city,
          firstname: add[0]?.firstname,
          lastname: add[0]?.lastname,
          default_shipping: add[0]?.default_shipping,
          default_billing: add[0]?.default_billing,
        };
        setFormData(data);
        setCustCountry(add[0]?.country_code);
        setCustState(add[0]?.region?.region_code);
      }
      console.log("Fetching address data");
    }
  }, [custDetails]);

  const [updateAddress] = useMutation(
    mutations.customerMutation.UPDATE_ADDRESS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );
  const handleInputChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "street") {
      setFormData({ ...formData, [name]: [value] });
    } else if (name === "region_code" || name === "region") {
      setFormData({
        ...formData,
        region:{
          ...formData.region,
          [name]: value
        }
      })
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const validateForm = () => {
    const newFormErr = { ...formErr }; // Create a copy of formErr
    if (formData.firstname === "") {
        newFormErr.firstname = "First name is required";
    } else {
        newFormErr.firstname = ""; // Clear the error if valid
    }
    if (formData.lastname === "") {
        newFormErr.lastname = "Last name is required";
    } else {
        newFormErr.lastname = ""; // Clear the error if valid
    }
    if (formData.telephone === "") {
        newFormErr.telephone = "Phone number is required";
    } else if (formData.telephone.length > 10) {
        newFormErr.telephone = "Enter valid phone number";
    } else {
        newFormErr.telephone = ""; // Clear the error if valid
    }
    if (formData.street[0] === "") {
        newFormErr.street = "Street is required";
    } else {
        newFormErr.street = ""; // Clear the error if valid
    }
    if (formData.city === "") {
        newFormErr.city = "City is required";
    } else {
        newFormErr.city = ""; // Clear the error if valid
    }
    if (formData.postcode === "") {
        newFormErr.postcode = "Zip/Postal code is required";
    } else {
        newFormErr.postcode = ""; // Clear the error if valid
    }
    setFormErr(newFormErr); // Update the state with the new form errors
};


  const handleUpdateAddress = (event: any) => {

    validateForm();
    event.preventDefault();
    const addressId = formData.id;
    // / const addressId = newAddressData?.id; /
    console.log("addressId",addressId)
    // / const addressInput = formData; /
    updateAddress({
      variables: {
        id: addressId,
        input: {
          region: {
            region: formData.region.region,
            region_code: formData.region.region_code,
          },
          company: "",
          country_code: formData.country_code,
          street: formData.street,
          telephone: formData.telephone,
          postcode: formData.postcode,
          city: formData.city,
          firstname: formData.firstname,
          lastname: formData.lastname,
          default_shipping: formData.default_shipping,
          default_billing: formData.default_billing,
        },
      },
    })
    .then(() => {
      alert('Address updated successfully!');
    })
    .catch((error) => {
      console.error('Error updating address:', error);
      alert('Error while updating')
    });
  };

  const handledCustContry = (event: SelectChangeEvent) => {
    setFormData({ ...formData, country_code: event.target.value });
    setCustCountry(event.target.value);
  };
  const handledCustState = (event: SelectChangeEvent) => {
    const rCode: any = state.filter(
      (item: any) => item.code === event.target.value
    );
    let region = {
      region: "",
      region_code: "",
    };
    if (rCode && rCode.length > 0) {
      region = {
        region: rCode && rCode[0]?.name,
        region_code: rCode[0]?.code,
      };
    }
    setFormData({ ...formData, region: region });
    setCustState(event.target.value);
  };

  if (
    params?.addressType === "edit-billing" ||
    params?.addressType === "edit-shipping"
  ) {
    onSubmitFunction = handleUpdateAddress;
  }
  console.log("custState", custState, "custCountry", custCountry)
  const handleBillingToggle = () => {
    setFormData({ ...formData, default_billing: !defaultBilling });
    setDefaultBilling(!defaultBilling);
  };
  const handleShippingToggle = () => {
    setFormData({ ...formData, default_shipping: !defaultShipping });
    setDefaultShipping(!defaultShipping);
  };
  return (
    <>
      <Box sx={{ width: "100%", bgcolor: "#F0F2F2", mb: 3, p: 1 }}>
        <Typography
          variant="h5"
          component="h5"
          sx={{ fontWeight: "600", fontSize: "20px" }}
        >
          {params?.addressType === "add-address"
            ? "ADD ADDRESS"
            : "EDIT ADDRESS"}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          bgcolor: "#F7F6F6",
          mb: 3,
          border: "1px solid #D2CDCD",
          p: 1,
        }}
      >
        <Typography
          variant="h6"
          component="h6"
          sx={{ fontSize: "18px", fontWeight: "500" }}
        >
          {t("buyer_dashboard.editAddrBook.content_info")}
        </Typography>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "background.paper", mb: 3 }}>
        <Card variant="outlined" sx={{ minWidth: "100%" }}>
          <CardContent sx={{ textAlign: "initial" }}>
            <Box
              component="form"
              noValidate
              // onSubmit={handleUpdateAddress}
              onSubmit={onSubmitFunction}
              // sx={{ mt: 3 }}
              autoComplete="off"
              className="edit-address_book"
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {" "}
                    {t("buyer_dashboard.editAddrBook.first_name")}
                    <span style={{ color: "#EA0C0C" }}>*</span>
                  </InputLabel>
                  <TextField
                    error={formErr.firstname !== ""}
                    autoComplete="off"
                    name="firstname"
                    required
                    fullWidth
                    id="firstname"
                    placeholder="First Name"
                    value={formData.firstname}
                    autoFocus
                    onChange={handleInputChange}
                    helperText={formErr.firstname}
                    // onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {t("buyer_dashboard.editAddrBook.last_name")}
                    <span style={{ color: "#EA0C0C" }}>*</span>
                  </InputLabel>
                  <TextField
                    autoComplete="given-name"
                    name="lastname"
                    required
                    fullWidth
                    id="lastname"
                    placeholder="Last Name"
                    value={formData.lastname}
                    autoFocus
                    onChange={handleInputChange}
                    error={formErr.lastname !== ""}
                    helperText={formErr.lastname}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {t("buyer_dashboard.editAddrBook.company")}
                  </InputLabel>
                  <TextField
                    autoComplete="given-company"
                    name="company"
                    required
                    fullWidth
                    id="company"
                    placeholder="Company"
                    value={formData.company}
                    autoFocus
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {t("buyer_dashboard.editAddrBook.phone_no")}
                    <span style={{ color: "#EA0C0C" }}>*</span>
                  </InputLabel>
                  <TextField
                    autoComplete="given-telephone"
                    name="telephone"
                    required
                    fullWidth
                    id="telephone"
                    placeholder="Phone Number"
                    value={formData.telephone}
                    autoFocus
                    onChange={handleInputChange}
                    error={formErr.telephone !== ""}
                    helperText={formErr.telephone}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      bgcolor: "#F7F6F6",
                      mb: 3,
                      border: "1px solid #D2CDCD",
                      p: 1,
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="h6"
                      sx={{ fontSize: "18px", fontWeight: "500" }}
                    >
                      {t("buyer_dashboard.editAddrBook.address")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {t("buyer_dashboard.editAddrBook.street_addr")}
                    <span style={{ color: "#EA0C0C" }}>*</span>
                  </InputLabel>
                  <TextField
                    autoComplete="given-streetadd"
                    name="street"
                    required
                    fullWidth
                    id="street"
                    placeholder="Street Address"
                    value={formData.street}
                    autoFocus
                    onChange={handleInputChange}
                    error={formErr.street !== ""}
                    helperText={formErr.street}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {t("buyer_dashboard.editAddrBook.country")}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    sx={{ border: "1px solid #C6C3C3", borderRadius: "2px" }}
                  >
                    <Select
                      IconComponent={CustomSelectIcon}
                      labelId="simple-select-label"
                      id="simple-select"
                      className="qty-select"
                      value={custCountry}
                      // label="Quantity"
                      onChange={handledCustContry}
                    >
                      {countries &&
                        countries.map((option: any) => (
                          <MenuItem
                            key={option?.two_letter_abbreviation}
                            value={option?.two_letter_abbreviation}
                          >
                            {option?.full_name_english}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {t("buyer_dashboard.editAddrBook.state")}
                  </InputLabel>
                  <FormControl
                    fullWidth
                    sx={{ border: "1px solid #C6C3C3", borderRadius: "2px" }}
                  >
                    <Select
                      IconComponent={CustomSelectIcon}
                      labelId="state-simple-select-label"
                      id="state-simple-select"
                      className="qty-select"
                      value={custState}
                      // label="Quantity"
                      onChange={handledCustState}
                    >
                      {state &&
                        state.map((option: any) => (
                          <MenuItem key={option?.code} value={option?.code}>
                            {option?.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {t("buyer_dashboard.editAddrBook.city")}
                    <span style={{ color: "#EA0C0C" }}>*</span>
                  </InputLabel>
                  <TextField
                    autoComplete="given-city"
                    name="city"
                    required
                    fullWidth
                    id="city"
                    placeholder="City"
                    value={formData.city}
                    autoFocus
                    onChange={handleInputChange}
                    error={formErr.city !== ""}
                    helperText={formErr.city}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                    {t("buyer_dashboard.editAddrBook.zip_code")}
                    <span style={{ color: "#EA0C0C" }}>*</span>
                  </InputLabel>
                  <TextField
                    autoComplete="given-postcode"
                    name="postcode"
                    required
                    fullWidth
                    id="postcode"
                    placeholder="Zip"
                    value={formData.postcode}
                    autoFocus
                    onChange={handleInputChange}
                    error={formErr.postcode !== ""}
                    helperText={formErr.postcode}
                  />
                </Grid>
                {/* <Grid item xs={12}>
                                    <Stack sx={{ width: '100%' }} spacing={2}>
                                        <Alert severity="warning">It's a default billing address.!</Alert>
                                        <Alert severity="warning">It's a default shipping address.!</Alert>
                                    </Stack>
                                </Grid> */}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      bgcolor: "#fff",
                      mb: 2,
                      textAlign: "initial",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={defaultBilling}
                          onChange={handleBillingToggle}
                          name="subscription"
                        />
                      }
                      label="Allow remote shopping assistance"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={defaultShipping}
                          onChange={handleShippingToggle}
                          name="subscription"
                        />
                      }
                      label="Allow remote shopping assistance"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{ width: "100%", bgcolor: "background.paper", mt: 3 }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      type="submit"
                      sx={{
                        my: 2,
                        textTransform: "capitalize",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "500",
                        padding: "6px 34px",
                      }}
                    >
                      {t("buyer_dashboard.editAddrBook.save_addr_btn")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
export default EditAddressBook;